<template>
	<breadcrumb  :home="home" :model="britems" />

	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>

				<DataTable ref="dt" :loading="loading2" :value="products" v-model:selection="selectedProducts" dataKey="id"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Events Responses</h5>							
						</div>
					</template>

					
					<Column field="name" header="Name"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.emailAddress.name}}
						</template>
					</Column>
					<Column field="address" header="Email Address"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Email Address</span>
							{{slotProps.data.emailAddress.address}}
						</template>
					</Column>
					<Column field="type" header="Type"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title p-column-success">Type</span>
							{{slotProps.data.type}}
						</template>
					</Column>
					
					
					<Column field="response" header="Response"  headerStyle="width:34%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Response</span>
							<div :class="stockClass(slotProps.data)">
								{{slotProps.data.status.response}}
							</div>
						</template>
					</Column>
				
				</DataTable>

			</div>
		</div>
	</div>

</template>

<script>
import SNService from "../../service/SNService";
import  'primevue/breadcrumb';


export default {
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            britems: [
                {label: 'Manage Events', to: '/sn'},
                {label: 'Roster', to: '/roster/approved/'+this.$route.params.id},
				{label: 'Invitees Response',disabled:true},
            ],
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			loading2 : true
			
		}
	},
	productService: null,
	created() {
		this.srateService = new SNService();
	},
	mounted() {
		console.log(this.$route.params);
		this.srateService.viewroster(this.$route.params.eventId).then((data) => {
				console.log(data);
                this.products = data.data.value
                this.loading2 = false;
                this.new_disabled=false;
            }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 6000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
                                    this.productToBeDeleted = -1;
                                });			
	},
	methods: {
		managevent(){
			this.$router.push('/sn');
		},
		
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		stockClass(data) {
            return [
                {
                    'lowstock': data.status.response=='none',
                    'instock': data.status.response=='accepted'
                 }
            ];
        }
	
	}
}
</script>


<style scoped lang="scss">
.outofstock {
    font-weight: 700;
    color: #FF5252;
    text-decoration: line-through;
}

.lowstock {
    font-weight: 700;
    color: #FFA726;
}

.instock {
    font-weight: 700;
    color: #66BB6A;
}

::v-deep(.row-accessories) {
    background-color: rgba(0,0,0,.15) !important;
}
</style>            